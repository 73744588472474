import React, { useContext, useEffect, useState } from "react";
import classes from "./Controls.module.css";
import Button from "../../components/Button/Button";
import { AppContext } from "./../../context/AppContext";
import { leaveChannel } from "../../apis";
import Dropdown from "../../utils/Dropdown/Dropdown";
import CloseModal from "./CloseModal";
import LeaveForced from "./LeaveForced";
import { toast } from "react-toastify";

import StudentRaiseHandControls from "../../components/StudentRaiseHandControls";
import {
  IconDropdown,
  IconMic,
  IconVideo,
  IconScreenshare,
  IconVideoDisable,
  IconMicDisable,
  IconRotate,
} from "../../utils/Icons";

export default function Controls(props) {
  const appContext = useContext(AppContext);
  const {
    localStreams,
    activeStreamId,
    userType,
    studentInvitation,
    cameraDevicesList,
    micDevicesList,
    activeCameraId,
    activeMicId,
    setContext,
    defaultIds,
  } = appContext;
  let activeStream;
  if (localStreams) {
    activeStream = localStreams[activeStreamId];
  }
  useEffect(() => {
    appContext.setContext({
      modal: {
        hidden: true,
        type: "",
      },
    });
  }, []);
  useEffect(() => {
    appContext.setContext({
      micStatus: false,
      isVideoSharing: false,
    });
  }, [appContext.isScreenSharing]);
  useEffect(() => {
    if (!activeStream) return;
    if (appContext.micStatus) {
      activeStream.muteAudio();
      toast.error("Mic Disabled");
    } else {
      activeStream.unmuteAudio();
      toast.success("Mic Enabled");
    }
  }, [appContext.micStatus]);
  useEffect(() => {
    if (!activeStream) return;
    if (appContext.isVideoSharing) {
      activeStream.muteVideo();
      toast.error("Video Disabled");
    } else {
      toast.success("Video Enabled");
      activeStream.unmuteVideo();
    }
  }, [appContext.isVideoSharing]);
  useEffect(() => {
    if (appContext.leaveClass)
      leaveChannel(appContext.sessionData, appContext.persistedData, () => {
        appContext.setContext({
          showLoader: true,
        });
        toast.success("Live session ended");
      });
  }, [appContext.leaveClass]);
  const handleScreenSharing = () => {
    appContext.setContext({
      isScreenSharing: !appContext.isScreenSharing,
    });
  };
  const handleMicClick = () => {
    appContext.setContext({
      micStatus: !appContext.micStatus,
      videoObjectFit: "contain",
    });
  };
  const handleVideoClick = () => {
    appContext.setContext({
      isVideoSharing: !appContext.isVideoSharing,
    });
  };
  const endLiveClass = () => {
    let { sessionData, rtm, userType, persistedData } = appContext;
    if (userType === "tutor") {
      // send leave class message
      let message = "%LIVE_SESSION_ENDED%";
      rtm.sendChannelMessage(message, sessionData.channelName);
    }

    appContext.setContext({
      leaveClass: !appContext.leaveClass,
    });
  };

  const confirmation = (type = "", hidden = true) => {
    appContext.setContext({
      modal: { type, hidden },
    });
  };

  let [current_rotation, rotateDiv] = useState(90);

  const rotateClick = () => {
    rotateDiv(current_rotation + 90);
    document.querySelector("#mainVideo").firstElementChild.style.transform =
      "rotate(" + current_rotation + "deg)";
  };

  return (
    <div className={classes.lowerUiBarActions}>
      <React.Fragment>
        {appContext.modal && appContext.modal.type == "CLOSE_LIVE" && (
          <CloseModal
            hidden={appContext.modal && appContext.modal.hidden}
            handleSuccess={endLiveClass}
            handleClose={confirmation}
            toggle={() => confirmation()}
            showLeaving={appContext.leaveClass}
            appContextData={appContext}
          />
        )}
        {appContext.modal && appContext.modal.type == "DYNAMIC_KEY_EXPIRED" && (
          <LeaveForced
            text={`Your class has been expired "${appContext.sessionData.title}"`}
            hidden={appContext.modal && appContext.modal.hidden}
            handleSuccess={endLiveClass}
            toggle={endLiveClass}
            showLeaving={appContext.leaveClass}
            appContextData={appContext}
          />
        )}
        {appContext.modal && appContext.modal.type == "CLIENT_BANNED" && (
          <LeaveForced
            text={`The live class has been ended`}
            hidden={appContext.modal && appContext.modal.hidden}
            handleSuccess={endLiveClass}
            toggle={endLiveClass}
            showLeaving={appContext.leaveClass}
            appContextData={appContext}
          />
        )}
        {appContext.modal && appContext.modal.type == "NotAllowedError" && (
          <LeaveForced
            text={"Unable to Access"}
            body={
              <div>
                <p>We are unable to access your device please check :</p>
                <p>1. Microphone & Camera Permissions</p>
                <p>
                  2. Please check if any another application is not using your
                  microphone or camera concurrently
                </p>
                <p>3. Your camera drivers and web browser is updated</p>
                <p>4. Reload after changing permissions</p>
              </div>
            }
            hidden={appContext.modal && appContext.modal.hidden}
            handleSuccess={() => confirmation()}
            toggle={() => confirmation()}
            showLeaving={appContext.leaveClass}
            appContextData={appContext}
          />
        )}
        {((studentInvitation && studentInvitation.status == "accepted") ||
          userType === "tutor") && (
          <>
            {userType == "tutor" && (
              <Button
                onClick={handleScreenSharing}
                className={
                  classes.Button
                  //+
                  //" " +
                  // (appContext.isScreenSharing ? classes.ButtonEnd : "")
                }
              >
                <IconScreenshare color="#333333" />
                <div style={{ marginLeft: "4px" }}>
                  {!appContext.isScreenSharing
                    ? "Share Screen"
                    : "Stop Screen Sharing"}
                </div>
              </Button>
            )}
            <div className={classes.Button}>
              <Button onClick={handleMicClick} className={classes.commonBtn}>
                {!appContext.micStatus ? (
                  <IconMic color="#333333" />
                ) : (
                  <IconMicDisable color="#333333" />
                )}
              </Button>
              <Dropdown
                name={<IconDropdown color="#333333" />}
                ddnClass={classes.DropdownItems}
                noCaret={true}
                items={
                  <React.Fragment>
                    {micDevicesList &&
                      micDevicesList.length &&
                      micDevicesList.map((mic) => {
                        return (
                          <li
                            className={
                              classes.listItems +
                              " " +
                              (activeMicId === mic.deviceId
                                ? classes.selected
                                : "")
                            }
                            onClick={() => {
                              //  appContext.agoraClient.changeStreamSource(
                              //    mic.deviceId,
                              //    "video"
                              //  );
                              setContext({
                                activeMicId: mic.deviceId,
                              });
                            }}
                          >
                            {mic.label}
                          </li>
                        );
                      })}
                  </React.Fragment>
                }
              />
            </div>

            {(appContext.sessionData.showVideo || userType == "tutor") && (
              <div className={classes.Button}>
                <Button
                  onClick={handleVideoClick}
                  className={classes.commonBtn}
                >
                  {!appContext.isVideoSharing ? (
                    <IconVideo color="#333333" />
                  ) : (
                    <IconVideoDisable color="#333333" />
                  )}
                </Button>
                <Dropdown
                  name={<IconDropdown color="#333333" />}
                  ddnClass={classes.DropdownItems}
                  noCaret={true}
                  items={
                    <React.Fragment>
                      {cameraDevicesList &&
                        cameraDevicesList.length &&
                        cameraDevicesList.map((camera) => {
                          return (
                            <li
                              className={
                                classes.listItems +
                                " " +
                                (activeCameraId === camera.deviceId
                                  ? classes.selected
                                  : "")
                              }
                              onClick={() => {
                                // appContext.agoraClient.changeStreamSource(
                                //   camera.deviceId,
                                //   "video"
                                // );
                                setContext({
                                  activeCameraId: camera.deviceId,
                                });
                              }}
                            >
                              {camera.label}
                            </li>
                          );
                        })}
                    </React.Fragment>
                  }
                />
              </div>
            )}
          </>
        )}
        {userType === "student" && <StudentRaiseHandControls />}
        {userType === "student" && !appContext.sessionData.isHostedOnWeb && (
          <Button onClick={rotateClick} className={classes.Button}>
            <IconRotate />
          </Button>
        )}
        <Button
          onClick={(e) => {
            confirmation("CLOSE_LIVE", false);
          }}
          className={classes.Button + " " + classes.ButtonEnd}
        >
          {appContext.userType === "tutor" ? "End Live Class" : "Leave Class"}
        </Button>

        {
          // <Button className={classes.Button}>{"left"}</Button>
          //  <span>{"Page 1 of 14"}</span>
          // <Button className={classes.Button}>{"right"}</Button>
        }
      </React.Fragment>
    </div>
  );
}
